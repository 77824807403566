import { clientFetch } from '@/common/api/rest/fetch.client'
import { setCookie } from '@/common/utils/cookie-utils'

export const VISITOR_ID_COOKIE_KEY = '_dgtvid'

function storeCookie(value: string): void {
  const expires = new Date()

  expires.setDate(expires.getDate() + 30)
  setCookie(VISITOR_ID_COOKIE_KEY, value, { expires })
}

export async function fetchVisitorId(oldVisitorId?: string): Promise<void> {
  const params = new URLSearchParams({
    url: window.location.origin + window.location.pathname,
    referrer: '',
    getParams: window.location.search,
  })
  const visitorId = await clientFetch<string>({
    url: '/rest/V1/dognet/visitorId?' + params.toString(),
    method: 'GET',
  })

  if (visitorId && oldVisitorId !== visitorId) {
    storeCookie(visitorId)
  }
}

export async function addVisitorId(
  visitorId: string | null,
  oldVisitorId?: string,
): Promise<void> {
  if (visitorId && visitorId !== oldVisitorId) {
    await clientFetch({
      url: '/rest/V1/dognet/visitorId',
      method: 'POST',
      body: {
        visitorId,
      },
    })
    storeCookie(visitorId)
  }
}
